import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import { motion } from "framer-motion";

const FlagSwitch = ({ onChangeLanguage }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    // Notify the parent component of the language change
    onChangeLanguage(checked ? "AR" : "EN");
  }, [checked, onChangeLanguage]);

  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };

  return (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <motion.div
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1.3, opacity: 1 }}
        transition={{ type: "spring", stiffness: 300, delay: 0.7 }}
      >
        <Switch
          checked={checked}
          onChange={handleChange}
          offColor="#f37c20"
          onColor="#f37c20"
          offHandleColor="#f37c20"
          onHandleColor="#f37c20"
          handleDiameter={26}
          uncheckedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2,
              }}
            >
              <p style={{ margin: 0, color: "#fff", fontWeight: "bold" }}>EN</p>
            </div>
          }
          checkedIcon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                fontSize: 12,
                color: "#fff",
                paddingRight: 2,
              }}
            >
              <p style={{ margin: 0, color: "#fff", fontWeight: "bold" }}>AR</p>
            </div>
          }
          uncheckedHandleIcon={
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Flag_of_the_United_States.svg/1200px-Flag_of_the_United_States.svg.png"
              alt="EN"
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            />
          }
          checkedHandleIcon={
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/Flag_of_Lebanon.svg/1200px-Flag_of_Lebanon.svg.png"
              alt="AR"
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
            />
          }
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={30}
          width={60}
        />
      </motion.div>
    </div>
  );
};

export default FlagSwitch;
