import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { db } from "./Firebase";
import {
  setDoc,
  collection,
  getDocs,
  query,
  doc,
  where,
} from "firebase/firestore";
import toast from "react-hot-toast";
import { Slider, ConfigProvider } from "antd";
import { motion } from "framer-motion";
import { RiseLoader } from "react-spinners";

function Modal({ modal, onClose, language }) {
  const colRef = collection(db, "FirstSession");
  const [ParentName, setParentName] = useState("");
  const [childName, setChildName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState(3);
  const [note, setNote] = useState("");
  const [noteLength, setNoteLength] = useState(0);
  const [phoneValid, setPhoneValid] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [Registered, setRegistered] = useState(true); // iza mawjud
  const [firstTimeRegistration, setFirstTimeRegistration] = useState(false); // awal mara
  const [error, setError] = useState(false);

  const notValid = !childName.trim() || !ParentName.trim() || !phone.trim();

  const searchPhone = async (phone) => {
    const phoneSearch = await getDocs(
      query(colRef, where("phone", "==", phone))
    );
    return phoneSearch.docs.length > 0;
  };

  const Reserve = async () => {
    try {
      if (!phoneValid) {
        toast.error(getTranslation("phoneValidationError"));
        return;
      }
      if (notValid) {
        toast.error(getTranslation("fieldValidationError"));
        return;
      }

      setButtonDisabled(true);
      const phoneExists = await searchPhone(phone);
      if (true) {
        const date = new Date();
        await setDoc(doc(colRef), {
          ParentName,
          ChildName: childName,
          age,
          phone,
          Note: note || "NA",
          Date: date,
        });
        setFirstTimeRegistration(true);
        setFormDisabled(true);
      } else {
        setRegistered(false);
      }
    } catch (err) {
      console.error("Firebase error:", err);
      setError(getTranslation("firebaseError"));
    } finally {
      setButtonDisabled(false);
    }
  };

  const handleNoteChange = (e) => {
    const text = e.target.value;
    setNote(text);
    setNoteLength(text.length);
    if (text.length === 250) {
      toast.error(getTranslation("noteLimitError"));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (age < 3 || age > 15) {
      toast.error(getTranslation("ageValidationError"));
      return;
    }
    await Reserve();
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    setPhone(input);
  };

  const validatePhoneNumber = (phone) => {
    const LebanesePrefixes = ["03", "71", "78", "76", "79", "70", "81"];
    return LebanesePrefixes.includes(phone.slice(0, 2));
  };

  const handlePhoneValidation = () => {
    if (phone === "") {
      return;
    }
    const isValidPhone = validatePhoneNumber(phone);
    setPhoneValid(isValidPhone);
    if (!isValidPhone) {
      toast.error(getTranslation("phoneValidationError"));
    }
  };

  if (!modal) return null;

  const modalVariants = {
    hidden: { opacity: 0, scale: 0.2, y: "-50%", x: "-50%" },
    visible: {
      opacity: 1,
      scale: 1,
      y: "-50%",
      x: "-50%",
      transition: { duration: 0.3 },
    },
    exit: {
      opacity: 0,
      scale: 0.2,
      y: "-50%",
      x: "-50%",
      transition: { duration: 0.3 },
    },
  };

  // Translation
  const translations = {
    EN: {
      phoneValidationError: "Please enter a valid phone number.",
      fieldValidationError: "Please fill all the required fields.",
      firebaseError: "Failed to connect to Firebase. Please try again later.",
      noteLimitError: "You have reached the character limit.",
      ageValidationError: "Age must be between 3 and 15.",
      registrationSuccess:
        "Thanks for registering for the first session! We're thrilled to have you onboard. We will be in touch with you soon.",
      alreadyRegistered:
        "It appears you are already registered. If you have any questions or need assistance, please don't hesitate to contact us!",
      formTitle: "Build Your First Robot 🤖",
      formSubtitle: `Fill in your information below to join us for <b>FREE</b>`,
      parentNamePlaceholder: "Parent's Name",
      childNamePlaceholder: "Child's Name",
      phonePlaceholder: "Phone Number",
      phoneTitle: "Enter the phone number",
      notePlaceholder: "Notes (optional)",
      submittingText: "Submitting...",
      submittedText: "Submitted!",
      submitText: "Submit!",
      oopsTitle: "Oops!🙁",
      contactUs: "Contact us",
      FirstimeSuccess: "Welcome Aboard! 🚀",
      Age: "Age",
      PhoneNumber: "+961",
    },
    AR: {
      phoneValidationError: ".يرجى إدخال رقم هاتف صحيح",
      fieldValidationError: ".يرجى ملء جميع الحقول المطلوبة",
      firebaseError: "فشل في الاتصال بـ Firebase. يرجى المحاولة لاحقًا.",
      noteLimitError: ".لقد وصلت إلى حد الأحرف",
      ageValidationError: "يجب أن يكون العمر بين 3 و 15.",
      registrationSuccess:
        ".شكرًا لتسجيلك في الجلسة الأولى! نحن متحمسون لاستضافتك. سنتواصل معك قريبًا",
      alreadyRegistered:
        "يبدو أنك مسجل بالفعل. إذا كانت لديك أي أسئلة أو تحتاج إلى مساعدة، فلا تتردد في الاتصال بنا!",
      formTitle: "بناء أول روبوت لك🤖",
      formSubtitle: "املأ معلوماتك أدناه للانضمام إلينا <b>مجانًا</b> ",
      parentNamePlaceholder: "اسم الوالد",
      childNamePlaceholder: "اسم الطفل",
      phonePlaceholder: "رقم الهاتف",
      phoneTitle: "أدخل رقم الهاتف",
      notePlaceholder: "ملاحظات (اختياري)",
      submittingText: "جاري الإرسال...",
      submittedText: "تم الإرسال!",
      submitText: "إرسال",
      oopsTitle: "عذرًا!🙁",
      contactUs: "اتصل بنا",
      FirstimeSuccess: "أهلاً وسهلاً!🚀 ",
      Age: "عمر",
      PhoneNumber: "+٩٦١",
    },
  };
  const getTranslation = (key) => {
    return translations[language][key] || key;
  };
  const { formSubtitle } = translations[language];

  return (
    <>
      <ConfigProvider
        direction={language === "AR" ? "rtl" : "ltr"}
        theme={{
          components: {
            Slider: {
              /* here is your component tokens */
              handleColor: "#f37c20",
              handleActiveColor: "#f37c20",
              dotActiveBorderColor: "#f37c20",
              dotBorderColor: "#f37c20",
              trackBg: "#f37c20",
              trackHoverBg: "#f37c20",
            },
          },
        }}
      >
        <div className="ModalBackdrop">
          <motion.div
            // className="ModalContainer "
            className={`ModalContainer ${language === "AR" ? "rtl" : ""}`}
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {error ? (
              <div>
                <div className="ModalHeader">
                  <h1>{getTranslation("oopsTitle")} </h1>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="2x"
                    onClick={onClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div>
                  <p>{error}</p>
                </div>
              </div>
            ) : firstTimeRegistration ? (
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
              >
                <div className="ModalHeader">
                  <h1 style={{ marginBottom: "3px" }}>
                    {getTranslation("FirstimeSuccess")}
                  </h1>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="2x"
                    onClick={onClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div>
                  <p style={{ fontSize: "1.1em" }}>
                    {getTranslation("registrationSuccess")}
                  </p>
                </div>
              </motion.div>
            ) : Registered ? (
              <>
                <div className="ModalHeader">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h1>{getTranslation("formTitle")}</h1>
                    <p
                      className="subtitle"
                      dangerouslySetInnerHTML={{ __html: formSubtitle }}
                    />
                  </div>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="2x"
                    onClick={onClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="inputContainer">
                    <input
                      type="text"
                      placeholder={getTranslation("parentNamePlaceholder")}
                      className="ModalInput form-control"
                      required
                      value={ParentName}
                      onChange={(e) => setParentName(e.target.value)}
                      minLength={2}
                      maxLength={50}
                      disabled={formDisabled}
                    />
                    <input
                      type="text"
                      placeholder={getTranslation("childNamePlaceholder")}
                      className="ModalInput form-control"
                      required
                      value={childName}
                      onChange={(e) => setChildName(e.target.value)}
                      minLength={2}
                      maxLength={50}
                      disabled={formDisabled}
                    />
                  </div>
                  <div className="inputContainer">
                    <div
                      className="phoneContainer"
                      style={{ paddingTop: "1%" }}
                    >
                      <input
                        type="text"
                        value={getTranslation("PhoneNumber")}
                        disabled
                        className={`form-control ModalInput country-code${
                          language === "AR" ? "rtl" : ""
                        }`}
                        // className="form-control ModalInput country-code"
                      />
                      <input
                        type="tel"
                        placeholder={getTranslation("phonePlaceholder")}
                        className={`ModalInput form-control phone-number${
                          language === "AR" ? "rtl" : ""
                        }`}
                        required
                        value={phone}
                        onChange={handlePhoneChange}
                        onBlur={handlePhoneValidation}
                        minLength={8}
                        maxLength={8}
                        title={getTranslation("phoneTitle")}
                        disabled={formDisabled}
                      />
                    </div>

                    <div className={`Range ${language === "AR" ? "rtl" : ""}`}>
                      {getTranslation("Age")}: {age}
                      <Slider
                        value={age}
                        min={3}
                        max={15}
                        onChange={(value) => setAge(value)}
                        disabled={formDisabled}
                      />
                    </div>
                  </div>
                  <textarea
                    name="message"
                    className="ModalInput1 form-control"
                    rows={3}
                    placeholder={getTranslation("notePlaceholder")}
                    maxLength={250}
                    value={note}
                    onChange={handleNoteChange}
                    style={{ resize: "none" }}
                    disabled={formDisabled}
                  />
                  <div
                    style={{
                      textAlign: "right",
                      fontSize: "0.8rem",
                      marginTop: "0.1rem",
                    }}
                  >
                    {noteLength}/250
                  </div>

                  <button
                    className="ModalbtnSubmit btn btn-primary"
                    type="submit"
                    disabled={buttonDisabled || formDisabled}
                  >
                    {buttonDisabled ? (
                      <RiseLoader color="#ffffff" size={10} />
                    ) : formDisabled ? (
                      getTranslation("submittedText")
                    ) : (
                      getTranslation("submitText")
                    )}
                  </button>
                </form>
              </>
            ) : (
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
              >
                <div className="ModalHeader">
                  <h1>{getTranslation("oopsTitle")}</h1>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="2x"
                    onClick={onClose}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div>
                  <p>
                    {getTranslation("alreadyRegistered")}{" "}
                    <a
                      href="//api.whatsapp.com/send?phone=+96171802989&text=Hello!"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {getTranslation("contactUs")}
                    </a>
                  </p>
                </div>
              </motion.div>
            )}
          </motion.div>
        </div>
      </ConfigProvider>
    </>
  );
}

export default Modal;
