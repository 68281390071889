import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";
import { About } from "./Components/About";

const currentPath = window.location.pathname;
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <>
  //   <BrowserRouter>
  //     <Routes>
  //       <Route path="/" element={<App />} />
  //       <Route path="/about" element={<About />} />
  //       <Route path="*" element={<App />} />
  //     </Routes>
  //   </BrowserRouter>
  // </>
  currentPath === "/about" ? <About /> : <App />
);
