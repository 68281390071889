// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBuV2nLDPq0gybcRv3uGMwdeSc_5TkFdxQ",
  authDomain: "robostars-18a3d.firebaseapp.com",
  projectId: "robostars-18a3d",
  storageBucket: "robostars-18a3d.appspot.com",
  messagingSenderId: "318765298108",
  appId: "1:318765298108:web:4e56d2ce38830ee4fcfbb9",
  measurementId: "G-8BGW8HDH3S"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { app, db };
