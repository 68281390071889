import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { motion } from "framer-motion";

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      staggerChildren: 0.3, // Delay between each child's animation
      duration: 0.8,
      ease: "easeOut",
    },
  },
};

const iconVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const SocialMediaIcons = () => {
  return (
    <motion.div
      style={styles.container}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.a
        href="https://www.facebook.com/robostarslb/"
        target="_blank"
        rel="noopener noreferrer"
        variants={iconVariants}
        whileHover={{ scale: 1.2, rotate: 15 }}
        whileTap={{ scale: 0.9 }}
        transition={{ duration: 0.3 }}
      >
        <FontAwesomeIcon icon={faFacebook} style={styles.icon} />
      </motion.a>
      <motion.a
        href="https://www.instagram.com/robostarslb"
        target="_blank"
        rel="noopener noreferrer"
        variants={iconVariants}
        whileHover={{ scale: 1.2, rotate: 15 }}
        whileTap={{ scale: 0.9 }}
        transition={{ duration: 0.3 }}
      >
        <FontAwesomeIcon icon={faInstagram} style={styles.icon} />
      </motion.a>
      <motion.a
        href="https://www.tiktok.com/@robostars7"
        target="_blank"
        rel="noopener noreferrer"
        variants={iconVariants}
        whileHover={{ scale: 1.2, rotate: 15 }}
        whileTap={{ scale: 0.9 }}
        transition={{ duration: 0.3 }}
      >
        <FontAwesomeIcon icon={faTiktok} style={styles.icon} />
      </motion.a>
    </motion.div>
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: "30px",
    left: "25px",
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    borderRadius: "20px",
    backdropFilter: "blur(10px)",
  },
  icon: {
    color: "#fff",
    fontSize: "32px",
    transition: "color 0.3s ease",
  },
};

export default SocialMediaIcons;
