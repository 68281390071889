import React, { useEffect, useState, useMemo } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";
import logo from "./Assets/logoWhite(2).png";
import { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
import Modal from "./Components/Modal";
import "./App.css";
import ParticlesJS from "./particles.json";
import particlesOptionsMobile from "./particlesMobile.json";
import FlagSwitch from "./Components/Switch";
import SocialMediaIcons from "./Components/SocialMediaIcons";

function App() {
  const [particlesLoaded, setParticlesLoaded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [animateLogo, setAnimateLogo] = useState(false);
  const [language, setLanguage] = useState("EN");

  useEffect(() => {
    const initializeParticles = async () => {
      await initParticlesEngine(async (engine) => {
        await loadFull(engine);
      });
      setParticlesLoaded(true);
    };

    initializeParticles();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const particlesOptions = useMemo(() => {
    return isMobile ? particlesOptionsMobile : ParticlesJS;
  }, [isMobile]);

  const particlesComponent = useMemo(() => {
    if (particlesLoaded) {
      return <Particles id="tsparticles" options={particlesOptions} />;
    }
    return null;
  }, [particlesLoaded, particlesOptions]);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleLogoLoad = () => {
    setLogoLoaded(true);
    setTimeout(() => {
      setAnimateLogo(true);
    }, 700);
  };

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    // console.log("Selected Language:", newLanguage);
  };

  return (
    <>
      <Toaster />
      <div className="App">
        {particlesComponent}
        <div className="App-header">
          <motion.div
            onClick={handleModalOpen}
            initial={{ scale: 0, opacity: 0 }}
            animate={
              animateLogo
                ? { scale: 1.2, opacity: 1 }
                : { scale: 0.8, opacity: 1 }
            }
            style={{ visibility: logoLoaded ? "visible" : "hidden" }}
          >
            <motion.img
              src={logo}
              className="App-logo"
              alt="logo"
              onLoad={handleLogoLoad}
              whileHover={{ scale: 1.2 }}
              transition={{ type: "spring", stiffness: 300 }}
            />
          </motion.div>
        </div>

        <div className="language-switch">
          <motion.a
            href="/about"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: "1.3rem", color: "#fff" }}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            About
          </motion.a>
          <FlagSwitch onChangeLanguage={handleLanguageChange} />
        </div>

        <SocialMediaIcons />

        {modalOpen && (
          <Modal
            modal={modalOpen}
            onClose={handleModalClose}
            language={language}
          />
        )}
      </div>
    </>
  );
}

export default App;
