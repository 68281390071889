import React, { useState } from "react";
import { motion } from "framer-motion";
import FlagSwitch from "./Switch";
import "./About.css";
import logo from "../Assets/logo.png";

export const About = () => {
  const [language, setLanguage] = useState("EN");
  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
    // console.log("Selected Language:", newLanguage);
  };
  const translations = {
    EN: {
      title: "About Us:",
      description: `At <b>RoboStars</b>, we are dedicated to igniting the curiosity and creativity of young minds through the exciting world of robotics and technology, guided by the principles of <b>STEM education</b>.`,
      mission: `<b>Our mission</b> is to provide children and teens with hands-on learning experiences that emphasize science, technology, engineering, and mathematics, fostering innovation, problem-solving, and teamwork.`,
      vision: `Offering engaging workshops, competitions, and educational resources, we empower the next generation of thinkers and creators to excel in a rapidly evolving digital world. Join us on this journey to inspire and equip the future stars of robotics!`,
      direction: "ltr",
    },
    AR: {
      title: "معلومات عنا:",
      description: `في <b>RoboStars</b>،نهدف إلى تحفيز فضول وإبداع الشباب من خلال عالم الروبوتات والتكنولوجيا المثير. نحن ملتزمون بتعليم الأطفال والمراهقين بأسلوب ممتع ومباشر، مع التركيز على مبادئ التعليم القائم على العلوم والتكنولوجيا والهندسة والرياضيات (STEM).
`,
      mission: `
نقدم تجارب تعليمية عملية تشمل ورش عمل تفاعلية ومسابقات تحفيزية وموارد تعليمية متنوعة. هدفنا هو تمكين الجيل الجديد من التفكير الإبداعي وحل المشكلات والعمل الجماعي، لمساعدتهم على التميز في عالم التكنولوجيا المتطور بسرعة.`,
      vision: `انضم إلينا لتكون جزءاً من رحلتنا في إلهام وتجهيز المستقبلين في مجال الروبوتات والتكنولوجيا!
`,
      direction: "rtl",
    },
  };
  const { title, description, mission, vision, direction } =
    translations[language];

  return (
    <div style={{ overflowY: "visible", overflowX: "visible", direction }}>
      <link
        href="https://cdn.jsdelivr.net/npm/remixicon@4.2.0/fonts/remixicon.css"
        rel="stylesheet"
      />
      <div className="language-switch">
        <FlagSwitch onChangeLanguage={handleLanguageChange} />
      </div>

      <section className="about section" id="about" key={language}>
        <div className="about__container container grid">
          <motion.h2
            className="section__title-1"
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7 }}
            dangerouslySetInnerHTML={{ __html: `<span><u>${title}</u></span>` }}
          />

          <div className="about__perfil">
            <motion.div
              className="about__image"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.3 }}
            >
              <img src={logo} alt="Robostars" className="about__img" />
              <motion.div
                className="geometric-box"
                initial={{ opacity: 0, rotate: 45 }}
                animate={{ opacity: 1, rotate: 0 }}
                transition={{ duration: 0.8, delay: 0.5 }}
              />
            </motion.div>
          </div>

          <motion.div
            className="about__info"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <p
              className="about__description"
              dangerouslySetInnerHTML={{ __html: description }}
            />

            <ul className="about__list">
              <li
                className="about__item"
                dangerouslySetInnerHTML={{ __html: mission }}
              />
              <br />
              <li
                className="about__item"
                dangerouslySetInnerHTML={{ __html: vision }}
              />
            </ul>

            <motion.div
              className="about__buttons"
              initial={{ opacity: 0, y: -30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
            >
              <a
                href="tel:+96171802989"
                className="iconslink"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="ri-phone-line" style={{ marginRight: "8px" }}></i>
              </a>
              <a
                href="//api.whatsapp.com/send?phone=+96171802989&text=Hello!"
                target="_blank"
                rel="noopener noreferrer"
                className="iconslink"
              >
                <i
                  className="ri-whatsapp-line"
                  style={{ marginRight: "8px" }}
                ></i>
              </a>
              <a
                href="mailto:robostarslb@gmail.com"
                className="iconslink"
                style={{ marginRight: "8px" }}
              >
                <i className="ri-mail-line"></i>
              </a>
            </motion.div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};
